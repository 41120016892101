const option_names = [
  {
    options: "options",
    answer: "option_answer",
  },
  {
    options: "option_one",
    answer: "option_one_answer",
  },
  {
    options: "option_two",
    answer: "option_two_answer",
  },
];
export function checkOptions(item: any) {
  for (const o of option_names) {
    // if key options, option_one, option_two..etc exists in item
    if (o.options in item) {
      // user has updated answer and/or answer exist, put
      if (o.answer in item && item[o.answer] !== undefined && item[o.answer] !== null) {
        let id = null;
        if (typeof item[o.answer] === "number") {
          id = item[o.answer];
        } else {
          id = item[o.answer]?.id;
        }
        item[o.options] = id;
      } else {
        // leave it for now
        if (typeof item[o.options] !== "number") {
          item[o.options] = "";
        }
      }
      delete item[o.answer];
    }
  }
}
